import React from 'react';
import loader from '../../../assets/loader.gif';
/**
 * Asynchronously loads the component for SolutinoPage
 */
import Loadable from 'react-loadable';
import '../../../styles/loader.scss';

const Loader = () =>    <div className='loader-container'> <img src={loader}></img></div>

export default Loadable({
  loader: () => import('./recruitment'),
  loading: Loader,
});