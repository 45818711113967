import initialState from './initialState';
import update from 'immutability-helper';
import {
  STORE_INDUSTRIES,
  STORE_JOBS,
  APPLY_JOB_SUCCESS_TOGGLE,
  APPLY_JOB_FAILED,
  JOB_ALREADY_APPLIED,
  STORE_SEARCH_BASED_JOBS,
  CLEAR_JOBS,
  SEARCH_FILTER_CHANGE
} from '../actions/vacancy';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,

export default function mainReducer(state = initialState, action) {

  switch (action.type) {
    
    case STORE_INDUSTRIES: {
      const { industries } = action.payload;
      return update(state, {
        industries: {$set: industries},
        search: {$set: false}
      })
    }
    case STORE_JOBS: {
      const { jobs } = action.payload;
      return update(state, {
        jobs: {$set: jobs},
        search_jobs: {$set: []},
        search: {$set: false}
      })
    }
    case APPLY_JOB_SUCCESS_TOGGLE: {
      return update(state, {
        jobAppliedSuccessful: {$apply: (val) => !val},
        jobAppliedFail: {$set: false},
        alreadyApplied: {$set: false},
        search: {$set: false}
      })
    }
    case APPLY_JOB_FAILED: {
      return update(state, {
        jobAppliedFail: {$set: true},
        search: {$set: false}
      })
    }
    case JOB_ALREADY_APPLIED: {
      return update(state, {
        alreadyApplied: {$set: true},
        search: {$set: false}
      })
    }
    case STORE_SEARCH_BASED_JOBS: {
      const { jobs } = action.payload;
      return update(state, {
        search_jobs: {$set: jobs},
        search: {$set: true},
        jobs: {$set: []}
      })
    }
    case CLEAR_JOBS: {
      return update(state, {
        search_jobs: {$set: []},
        jobs: {$set: []},
        searchFilters: {$set: {}},
        search: {$set: false}

      })
    }
    case SEARCH_FILTER_CHANGE: {
      const {filter, val} = action.payload;
      return update(state, {
        searchFilters: {
          [filter]: {$set: val}
        }   
      })
    }
    default:
      return state;
  }
}