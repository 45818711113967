import { BASE_API_URL } from '../constants/config';
import axios from 'axios';

export const STORE_INDUSTRIES = 'STORE_INDUSTRIES';
export const STORE_JOBS = 'STORE_JOBS';
export const APPLY_JOB = 'APPLY_JOB';
export const APPLY_JOB_SUCCESS_TOGGLE =  'APPLY_JOB_SUCCESS_TOGGLE';
export const APPLY_JOB_FAILED = 'APPLY_JOB_FAILED';
export const JOB_ALREADY_APPLIED = 'JOB_ALREADY_APPLIED';
export const STORE_SEARCH_BASED_JOBS = 'STORE_SEARCH_BASED_JOBS';
export const CLEAR_JOBS = 'CLEAR_JOBS';
export const SEARCH_FILTER_CHANGE = 'SEARCH_FILTER_CHANGE';

export const fetchIndustries = () => {
    return (dispatch) => {
        axios.get(`${BASE_API_URL}/industries`)
        .then(function (resp) {
            dispatch({
                type: STORE_INDUSTRIES,
                payload: {industries: resp.data}
            })
        })
        .catch();
    }
}

export const fetchJobsByIndustry = (id) => {
    return (dispatch) => {
        axios.get(`${BASE_API_URL}/jobs_by_industry?industry_id=${id}`)
        .then(function (resp) {
            dispatch({
                type: STORE_JOBS,
                payload: {jobs: resp.data}
            })
        })
        .catch();
    }
}

export const fetchSearchBasedJobs = (query_params) => {
    return (dispatch) => {
        axios.post(`${BASE_API_URL}/job_search`, query_params)
            .then(function (resp) {
                dispatch({
                    type: STORE_SEARCH_BASED_JOBS,
                    payload: {jobs: resp.data}
                })
            })
            .catch();
    }
}

export const applyJob = (formData) => {
    return (dispatch) => {
        axios.post(`${BASE_API_URL}/apply_job`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })
        .then(function (resp) {
            if (resp.data.status === 1) {
                dispatch({
                    type: APPLY_JOB_SUCCESS_TOGGLE
                })
                setTimeout(() => {
                    dispatch({
                        type: APPLY_JOB_SUCCESS_TOGGLE
                    })
                }, 3000)
            } else if (resp.data.status === 3) {
                dispatch({
                    type: JOB_ALREADY_APPLIED
                })
            } else {
                dispatch({
                    type: APPLY_JOB_FAILED
                })
            }
        })
        .catch();
    }
}

export const clearJobs = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_JOBS
        })
    }
}

export const onSearchFilterChange = (filter, val) => {
    return (dispatch) => {
        dispatch({
            type: SEARCH_FILTER_CHANGE,
            payload: {filter, val}
        })
    }
}