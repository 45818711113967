import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { hot } from "react-hot-loader";
import MainPage from "./main/loadable";

import SolutionPage from "./services/SolutionPage/loadable";
import OrganizationalStrategy from "./services/organizationalStrategy/loadable";
import OrganizationEffectiveness from "./services/organizationEffectiveness/loadable";
import CulturalTransformation from "./services/culturalTransformation/loadable";
import StrategicWorforcePlanning from "./services/strategicWorkforcePlanning/loadable";
import EmployeeEngagement from "./services/employeeEngagement/loadable"
import PerformanceManagement from "./services/performanceManagement/loadable";
import HrEffectiveness from "./services/hrEffectiveness/loadable";
import TalentAcquisition from "./services/talentAcquisition/loadable";
import BoardAndCeos from "./services/boardAndCeo/loadable";
import Recruitment from "./services/recruitment/loadable";
import ProfessionalSearch from "./services/professionalSearch/loadable";
import ExecutiveSearch from "./services/executiveSearch/loadable";

import ChallengesPage from "./Challenges/challengesPage/loadable";
import DigitalTransformation from "./Challenges/digitalTransformation/loadbale";
import Diversity from "./Challenges/diversity/loadbale";
import Future from "./Challenges/futureOfWork/loadbale";
import GenderPay from "./Challenges/genderPay/loadbale";
import Mergers from "./Challenges/mergers/loadbale";
import SalesForce from "./Challenges/salesForce/loadbale";
import GotoMarket from "./Challenges/gotoMarket/loadbale";
import SalesOrg from "./Challenges/salesOrganization/loadbale";
import SalesPerformance from "./Challenges/salesPerformance/loadbale";
import SalesRoles from "./Challenges/salesRoles/loadbale";
import SalesCompensation from "./Challenges/salesCompensation/loadbale";
import WomenInLeadership from "./Challenges/womenInLeaderShip/loadbale";

import IndustriesPage from "./Industries/industriesPage/loadable";
import Consumer from "./Industries/consumer/loadable";
import Financial from "./Industries/financial/loadable";
import HealthCare from "./Industries/healthcare/loadable";
import LifeSciences from "./Industries/lifeSciences/loadable";
import Industrial from "./Industries/industrial/loadable";
import PublicService from "./Industries/publicService/loadable";
import TechnologyPage from "./Industries/technology/loadable";
import PrivateMarkets from "./Industries/privateMarkets/loadbale";

import FunctionsPage from "./Functions/functionsPage/loadable";
import TechOfficers from "./Functions/techOfficers/loadbale";
import Sustainability from "./Functions/sustainability/loadbale";
import SupplyChain from "./Functions/supplyChain/loadbale";
import RiskManagement from "./Functions/riskManagement/loadbale";
import Marketing from "./Functions/marketing/loadbale";
import Legal from "./Functions/legal/loadbale";
import HrResources from "./Functions/humanResources/loadbale";
import FinancialOfficers from "./Functions/financialOfficers/loadbale";
import Digital from "./Functions/digital/loadbale";
import CyberSecurity from "./Functions/cybersecurity/loadbale";

import VacanciesPage from "./Vacancies/loadable";

import AboutPage from "./About/aboutPage/loadable";
import CareerPage from "./About/careerPage/loadable";
import ExperiencedProfessionalsPage from "./About/experiencedProfessionals/loadable";
import RecentGraduates from "./About/recentGraduates/loadable";
import Contactpage from "./Contact/loadable";
import "../styles/app.css";

class App extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route 
            exact path="/"
            render={() => 
              <MainPage {...this.props}/>
            }
          />


          <Route 
            exact path="/services"
            render={() => 
              <MainPage {...this.props} renderedComponent={SolutionPage} />
            }
          />
          <Route 
            exact path="/services/organizational_strategy"
            render={() => 
              <MainPage {...this.props} renderedComponent={OrganizationalStrategy} />
            }
          />
          <Route 
            exact path="/services/organizational_effectiveness"
            render={() => 
              <MainPage {...this.props} renderedComponent={OrganizationEffectiveness} />
            }
          />
          <Route 
            exact path="/services/cultural_transformation"
            render={() => 
              <MainPage {...this.props} renderedComponent={CulturalTransformation} />
            }
          />
          <Route 
            exact path="/services/strategic_planning"
            render={() => 
              <MainPage {...this.props} renderedComponent={StrategicWorforcePlanning} />
            }
          />
          <Route 
            exact path="/services/employee_engagement"
            render={() => 
              <MainPage {...this.props} renderedComponent={EmployeeEngagement} />
            }
          />
          <Route 
            exact path="/services/performance_management"
            render={() => 
              <MainPage {...this.props} renderedComponent={PerformanceManagement} />
            }
          />
          <Route 
            exact path="/services/hr_effectiveness"
            render={() => 
              <MainPage {...this.props} renderedComponent={HrEffectiveness} />
            }
          />
          <Route 
            exact path="/services/talent_acquisition"
            render={() => 
              <MainPage {...this.props} renderedComponent={TalentAcquisition} />
            }
          />
          <Route 
            exact path="/services/board_n_ceos"
            render={() => 
              <MainPage {...this.props} renderedComponent={BoardAndCeos} />
            }
          />
          <Route 
            exact path="/services/recruitment_process_outsourcing"
            render={() => 
              <MainPage {...this.props} renderedComponent={Recruitment} />
            }
          />
          <Route 
            exact path="/services/professional_search"
            render={() => 
              <MainPage {...this.props} renderedComponent={ProfessionalSearch} />
            }
          />
          <Route 
            exact path="/services/professional_search"
            render={() => 
              <MainPage {...this.props} renderedComponent={ProfessionalSearch} />
            }
          />
          <Route 
            exact path="/services/executive_search"
            render={() => 
              <MainPage {...this.props} renderedComponent={ExecutiveSearch} />
            }
          />


          <Route 
            exact path="/trends"
            render={() => 
              <MainPage {...this.props} renderedComponent={ChallengesPage} />
            }
          />
          <Route 
            exact path="/trends/digital_transformation"
            render={() => 
              <MainPage {...this.props} renderedComponent={DigitalTransformation} />
            }
          />
          <Route 
            exact path="/trends/diversity"
            render={() => 
              <MainPage {...this.props} renderedComponent={Diversity} />
            }
          />
          <Route 
            exact path="/trends/future"
            render={() => 
              <MainPage {...this.props} renderedComponent={Future} />
            }
          />
          <Route 
            exact path="/trends/gender_pay"
            render={() => 
              <MainPage {...this.props} renderedComponent={GenderPay} />
            }
          />
          <Route 
            exact path="/trends/mergers_n_acquisitions"
            render={() => 
              <MainPage {...this.props} renderedComponent={Mergers} />
            }
          />
          <Route 
            exact path="/trends/sales_force"
            render={() => 
              <MainPage {...this.props} renderedComponent={SalesForce} />
            }
          />
          <Route 
            exact path="/trends/go_to_market"
            render={() => 
              <MainPage {...this.props} renderedComponent={GotoMarket} />
            }
          />
          <Route 
            exact path="/trends/sales_organization_structure"
            render={() => 
              <MainPage {...this.props} renderedComponent={SalesOrg} />
            }
          />
          <Route 
            exact path="/trends/sales_force_roles"
            render={() => 
              <MainPage {...this.props} renderedComponent={SalesRoles} />
            }
          />
          <Route 
            exact path="/trends/sales_compensation"
            render={() => 
              <MainPage {...this.props} renderedComponent={SalesCompensation} />
            }
          />
          <Route 
            exact path="/trends/sales_performance"
            render={() => 
              <MainPage {...this.props} renderedComponent={SalesPerformance} />
            }
          />
          <Route 
            exact path="/trends/women_in_leadership"
            render={() => 
              <MainPage {...this.props} renderedComponent={WomenInLeadership} />
            }
          />


          <Route 
            exact path="/industries"
            render={() => 
              <MainPage {...this.props} renderedComponent={IndustriesPage} />
            }
          />
          <Route 
            exact path="/industries/consumer"
            render={() => 
              <MainPage {...this.props} renderedComponent={Consumer} />
            }
          />
          <Route 
            exact path="/industries/financial"
            render={() => 
              <MainPage {...this.props} renderedComponent={Financial} />
            }
          />
          <Route 
            exact path="/industries/healthcare"
            render={() => 
              <MainPage {...this.props} renderedComponent={HealthCare} />
            }
          />
          <Route 
            exact path="/industries/life_sciences"
            render={() => 
              <MainPage {...this.props} renderedComponent={LifeSciences} />
            }
          />
          <Route 
            exact path="/industries/industrial"
            render={() => 
              <MainPage {...this.props} renderedComponent={Industrial} />
            }
          />
          <Route 
            exact path="/industries/public_service"
            render={() => 
              <MainPage {...this.props} renderedComponent={PublicService} />
            }
          />
          <Route 
            exact path="/industries/technology"
            render={() => 
              <MainPage {...this.props} renderedComponent={TechnologyPage} />
            }
          />
          <Route 
            exact path="/industries/private_markets"
            render={() => 
              <MainPage {...this.props} renderedComponent={PrivateMarkets} />
            }
          />


          <Route 
            exact path="/functions"
            render={() => 
              <MainPage {...this.props} renderedComponent={FunctionsPage} />
            }
          />
          <Route 
            exact path="/functions/technology_officers"
            render={() => 
              <MainPage {...this.props} renderedComponent={TechOfficers} />
            }
          />
          <Route 
            exact path="/functions/sustainability"
            render={() => 
              <MainPage {...this.props} renderedComponent={Sustainability} />
            }
          />
          <Route 
            exact path="/functions/supply_chain"
            render={() => 
              <MainPage {...this.props} renderedComponent={SupplyChain} />
            }
          />
          <Route 
            exact path="/functions/risk_management"
            render={() => 
              <MainPage {...this.props} renderedComponent={RiskManagement} />
            }
          />
          <Route 
            exact path="/functions/marketing"
            render={() => 
              <MainPage {...this.props} renderedComponent={Marketing} />
            }
          />
          <Route 
            exact path="/functions/legal"
            render={() => 
              <MainPage {...this.props} renderedComponent={Legal} />
            }
          />
          <Route 
            exact path="/functions/human_resources"
            render={() => 
              <MainPage {...this.props} renderedComponent={HrResources} />
            }
          />
          <Route 
            exact path="/functions/financial_officers"
            render={() => 
              <MainPage {...this.props} renderedComponent={FinancialOfficers} />
            }
          />
          <Route 
            exact path="/functions/digital"
            render={() => 
              <MainPage {...this.props} renderedComponent={Digital} />
            }
          />
          <Route 
            exact path="/functions/cyber_security"
            render={() => 
              <MainPage {...this.props} renderedComponent={CyberSecurity} />
            }
          />
          <Route 
            exact path="/functions/sales"
            render={() => 
              <MainPage {...this.props} renderedComponent={SalesForce} />
            }
          />
          <Route 
            exact path="/functions/boards"
            render={() => 
              <MainPage {...this.props} renderedComponent={BoardAndCeos} />
            }
          />


          <Route 
            exact path="/vacancies"
            render={() => 
              <MainPage {...this.props} renderedComponent={VacanciesPage} />
            }
          />
          <Route 
            exact path="/about"
            render={() => 
              <MainPage {...this.props} renderedComponent={AboutPage} />
            }
          />
          <Route 
            exact path="/about/career"
            render={() => 
              <MainPage {...this.props} renderedComponent={CareerPage} />
            }
          />
          <Route 
            exact path="/about/career/experienced_professionals"
            render={() => 
              <MainPage {...this.props} renderedComponent={ExperiencedProfessionalsPage} />
            }
          />
          <Route 
            exact path="/about/career/recent_graduates"
            render={() => 
              <MainPage {...this.props} renderedComponent={RecentGraduates} />
            }
          />
          <Route 
            exact path="/contact"
            render={() => 
              <MainPage {...this.props} renderedComponent={Contactpage} />
            }
          />
        </Switch>
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.element
};

export default hot(module)(App);